<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
// import './qiankun'

export default {
  data () {
    return {
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
<style lang="less">
.container {
  // padding: 0 !important;

  // .desc {
  //   display: none !important;
  // }

  // .header {
  //   display: none !important;

  // }

}

.setting-drawer-index-handle {
  display: none;
}

.distpicker-address-wrapper select {
  height: 37px !important;
  width: 200px !important;
  padding: 0 !important;
  font-size: 14px !important;
  border-radius: 6px !important;

}

.ant-layout-footer {
  height: 0 !important;
  display: none !important;
}

.ant-tabs-nav-container {
  display: none !important;
}

.setting-drawer-index-handle {
  display: none !important;
}

.ant-page-header {
  display: none !important;
}
</style>
